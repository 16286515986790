import { ButtonText } from '@components/Texts/Texts';
import tw, { css, styled, theme } from 'twin.macro';
import { ButtonPropsWithIsTouch } from './Button.types';

const primaryStyle = (isTouch: boolean, loadingState: boolean | undefined) => css`
  ${tw`text-dark-high font-bold`};
  ${isTouch ? tw`active:bg-button-primary-hover` : tw`hover:bg-button-primary-hover`}
  ${tw`disabled:bg-button-primary-disabled`};
  ${loadingState === true ? tw`bg-brand-secondary hover:bg-brand-secondary active:bg-brand-secondary` : tw`bg-brand-primary`};
  transition: background-color 100ms ease;

  &:active {
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 2px ${theme`colors.button.primary`};
    ${tw`focus:bg-button-primary-hover`};
  }
`;

const secondaryStyle = (isTouch: boolean) => css`
  ${tw`bg-brand-secondary text-light-high font-bold`};
  ${isTouch ? tw`active:bg-button-secondary-hover` : tw`hover:bg-button-secondary-hover`}
  ${tw`disabled:bg-button-secondary-disabled`};

  &:active {
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 2px ${theme`colors.button.secondary`};
    ${tw`focus:bg-button-secondary-hover`};
  }
`;

export const StyledButton = styled.button<ButtonPropsWithIsTouch>`
  ${ButtonText};
  ${tw`w-full h-8 flex items-center justify-center px-6 py-3`};
  ${props => (props.variant === 'secondary' ? secondaryStyle(props.isTouch) : primaryStyle(props.isTouch, props.loadingState))};
  outline: none !important;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  border-radius: ${props => (props.rounded ? `64px` : '0')};
`;
