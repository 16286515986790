import loaderAnimation from './Loader.json';

export const LoaderOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
