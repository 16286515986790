import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { StyledButton } from './Button.styles';
import { ButtonProps } from './Button.types';
import { LoaderOptions } from './LoaderOptions';

StyledButton.defaultProps = {
  text: '',
  rounded: true,
  variant: 'primary',
  uppercase: false,
  isTouch: false,
};

const Button = ({ text, loadingState, ...props }: ButtonProps): JSX.Element => {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    setIsTouch('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
  }, []);

  return (
    <StyledButton isTouch={isTouch} text={text} loadingState={loadingState} {...props}>
      {loadingState === true ? <Lottie options={LoaderOptions} height={64} width={64} /> : text}
    </StyledButton>
  );
};

export default Button;
